import { useEffect, useState } from "react";
import { GameHandler } from "../../domain/game/GameHandler";

const gameHandler = new GameHandler();

interface GameParameters {
    instanceId: string,
    type: string,
    onGameEnd: (message: string) => void
}

const GameWindow = ({ instanceId, type, onGameEnd }: GameParameters) => {
    const [url, setUrl] = useState<string | undefined>(undefined);
    const handleGameEvents = (event: MessageEvent<any>) => {
        if (event.data === 'Game Over' || event.data === 'Win') {
            onGameEnd(event.data);
        }
    }

    useEffect(() => {
        const result = gameHandler.getGameUrl(instanceId, type);

        setUrl(result);
    }, [instanceId, type])

    useEffect(() => {
        const item = localStorage.getItem("backendUrl"); 

        window.addEventListener(
            "message",
            handleGameEvents
        );

        if (
            (item == null && process.env.REACT_APP_BACKEND_URL) || 
            (item !== process.env.REACT_APP_BACKEND_URL && process.env.REACT_APP_BACKEND_URL)
        ) {
            localStorage.setItem("backendUrl", process.env.REACT_APP_BACKEND_URL)
        }

        return () => {
            window.removeEventListener("message", handleGameEvents)
        }
    }, [])


    return (
        <div className="w-full h-full">
            <iframe
                style={{ width: '100%', height: '100%', border: 'none' }}
                allowFullScreen={true}
                src={url}
                title={type + " Game"}
                width={1280}
                height={720}
            />
        </div>
    );
}

export default GameWindow;