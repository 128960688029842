import { FaCertificate, FaCheckCircle, FaClock, FaHome } from "react-icons/fa";
import { Game, GAMES } from "../../constants/games";
import { GameCard } from "../../components/game/GameCard";


const HomeErgo = () => {

    return (
        <div className="flex flex-col flex-1 w-full">
            <div className="w-full p-5 bg-white rounded-3xl min-h-full">
                <h1 className="text-4xl mb-5 px-5 text-deep-purple-700 font-bold">
                    <FaHome className="inline align-middle" />
                    <span className="ml-2 align-middle">
                        Accueil
                    </span>
                </h1>
                <h2 className="text-xl mb-2 px-5">
                    Nouveau jeu
                </h2>
                <img src="/assets/illustrations/preview_platformer.png" className="rounded-lg flex gap-20 items-center justify-between object-cover h-96 w-full" />
                <h2 className="text-2xl font-bold mt-5 pl-6">
                    Jeux disponibles
                </h2>
                <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 h-max w-full px-3 gap-4 m-2">
                    {GAMES.map((game: Game) => (
                        <GameCard patientId={""} game={game} key={game.name} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default HomeErgo;
