import { ProfessionalResponse } from "./dto/professional.response";
import { RegisterProfessionalRequest } from "./dto/register-professional.request";
import { LoginRequest } from "./dto/login.request";
import { LoginResponse } from "./dto/login.response";
import { axiosClient } from "../client";

export class AuthApi {
    public static async login(body: LoginRequest): Promise<LoginResponse> {
        const response = await axiosClient.post<LoginResponse>('/auth/login', body);
        return response.data
    }

    public static async registerProfessional(body: RegisterProfessionalRequest): Promise<ProfessionalResponse> {
        const response = await axiosClient.post<ProfessionalResponse>('/professionals', body);
        return response.data
    }
}