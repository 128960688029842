import axios from "axios"
import { GameTypesReponse } from "./dto/game-types.response";
import { GameSettingsStructureResponse } from "./dto/game-settings-structure.response";
import { FindPatientGameSettingsRequest } from "./dto/find-patient-game-settings.request";
import { authenticatedClient, updateAuthHeader } from "../client";

export default class GameSettingsApi {

    public static async getSettingsTypes() {
        updateAuthHeader();
        return await authenticatedClient.get<GameTypesReponse>('/game-settings/types');
    }

    public static async getSettingsTypeStructure(type: string) {
        updateAuthHeader();
        return authenticatedClient.get<GameSettingsStructureResponse>(
            `/game-settings/types/${type}/settings-structure`);
    }

    public static async getPatientSettings(request: FindPatientGameSettingsRequest) {
        updateAuthHeader();
        return authenticatedClient.get(
            `/game-settings/${request.gameType}/patient/${request.patientId}`);
    }
}