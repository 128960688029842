import { Text, TextStyle } from "@pixi/text";
import { CountDownComponent } from "../../common/Component/CountDownComponent";
import { Container } from "@pixi/display";
import { saveGame } from "../../common/server/SpaceGameParameter";
import { SoupGameConfiguration } from "../Game";

enum GameState {
    IN_GAME,
    GAME_OVER,
    WIN
}

export class GameRule {
    private status!: GameState;
    private score!: number;
    private countDown!: CountDownComponent
    private isEndTriggerd!: boolean;
    private lives!: number;

    private scoreText!: Text;
    private countDownText!: Text;
    private livesText!: Text;


    constructor(
        screen: { width: number, height: number },
        container: Container,
        private readonly config: SoupGameConfiguration,
    ) {
        const textStyle = new TextStyle({
            fill: 0xffffff,
            fontSize: screen.width * 0.03,
            fontFamily: 'Titan One'
        });

        this.status = GameState.IN_GAME;
        this.score = 0;
        this.isEndTriggerd = false;
        this.lives = this.config.lives;

        this.countDownText = new Text(this.getCountdownText(), textStyle);
        this.countDown = new CountDownComponent(
            { minutes: this.config.time.minute, seconds: this.config.time.second, miliseconds: 0 },
            textStyle
        )
        this.scoreText = new Text(this.getScoreText(), textStyle);
        this.livesText = new Text(this.getLivesText(), textStyle);
        
        if (this.config.interface.displayCountdown) {
            container.addChild(this.countDown.container);
        }
        if (this.config.interface.displayScore) {
            container.addChild(this.scoreText);
        }
        if (this.config.interface.displayLives) {
            container.addChild(this.livesText);
        }

        this.scoreText.position.set(
            screen.width * 0.02,
            screen.height * 0.90,
        )
        this.countDown.container.position.set(
            screen.width * 0.02,
            screen.height * 0.01    
        )
        this.livesText.position.set(
            screen.width * 0.87,
            screen.height * 0.90,
        )


        this.countDown.start();
    }

    private getScoreText() {
        return 'Score : ' + this.score;
    }

    private getLivesText() {
        return 'Vies : ' + this.lives;
    }

    private getCountdownText() {
        return 'Temps : ';
    }

    loseLife() {
        if (this.lives == 0) {
            return;
        }

        this.lives--;
        this.livesText.text = this.getLivesText();
    }

    wordTyped(word: string) {
        const scoreMul = 10;

        this.score += word.length * scoreMul;
        this.scoreText.text = this.getScoreText();
    }

    private updateGameStatus() {
        if (!this.countDown.isRunning) {
            this.status = GameState.WIN;
            return;
        }
        if (this.lives == 0) {
            this.status = GameState.GAME_OVER;
            return;
        }
    }

    update() {
        switch (this.status) {
            case GameState.WIN:
                if (!this.isEndTriggerd) {
                    saveGame(this.config.gameInstanceId).then(() => {
                        window.parent.postMessage("Win")
                    });
                    this.isEndTriggerd = true;
                }
                return;
            
            case GameState.GAME_OVER:
                if (!this.isEndTriggerd) {
                    saveGame(this.config.gameInstanceId).then(() => {
                        window.parent.postMessage("Game Over")
                    });
                    this.isEndTriggerd = true;
                }
                return;
            

            default:
                this.countDown.update();
                this.updateGameStatus();
                return;
        }
    }

    getScore() {
        return this.score;
    }

    getDisplayableElements() {
        return [this.countDown.container]
    }
}