const backendUrl = process.env.REACT_APP_BACKEND_URL;

export async function getParameters(id: string) {
    const bearer = localStorage.getItem('bearer');

    const response = await fetch(backendUrl + '/games/' + id, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + bearer,
            'accept': '*/*'
        }
    });

    if (!response.ok) {
        throw new Error(`Error fetching Game: ${response.statusText}`);
    }

    const jsonResponse = await response.json();

    return jsonResponse;
}

export async function saveGame(id: string) {
    const bearer = localStorage.getItem('bearer');

    const response = await fetch(backendUrl + '/games/finish/' + id, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + bearer,
            'accept': '*/*'
        }
    });

    if (!response.ok) {
        throw new Error(`Error fetching Game: ${response.statusText}`);
    }

    const jsonResponse = await response.json();

    return jsonResponse;
}