import { Link } from "react-router-dom";
import { useState } from "react";
import { Popover, PopoverHandler, PopoverContent } from "@material-tailwind/react";
import { Game } from "../../constants/games";
import { useModal } from "../../providers/ModalProvider";
import { GameDetails } from "./GameDetails";
import { toast } from "react-toastify";

const CardWrapper = (props: { children: React.ReactNode }) => {
  return (
    <div className="flex flex-col items-center justify-center flex-grow flex-shrink w-full rounded-lg hover:transform hover:scale-105 transition-transform overflow-hidden bg-gray-50">
      {props.children}
    </div>
  );
};

export const GameCard = (props: Readonly<{ patientId: string, game: Game, closeParent?: () => void }>) => {
  const { game, patientId, closeParent } = props;
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const { openModal } = useModal();

  const handleGameClick = () => {
    if (!patientId)
        return toast.info("Veuillez sélectionner un patient pour jouer à ce jeu");
    closeParent && closeParent();
    openModal(<GameDetails patientId={patientId} game={game} />);
  }

  const handlePopoverToggle = () => {
    setPopoverOpen(!isPopoverOpen);
  };

  const GameCardContent = () => (
    <CardWrapper>
      <div className="relative w-full h-48">
        <img
          src={game.image ?? "/assets/illustrations/no-image.jpg"}
          alt={game.name}
          className="w-full h-full object-cover"
        />
      </div>
      <div className="p-3 text-center">
        <p className="font-semibold text-center text-gray-800 text-lg">{game.name}</p>
        <p className="text-sm text-gray-800 text-ellipsis text-nowrap max-w-80 overflow-hidden">{game.summary}</p>
      </div>
    </CardWrapper>
  );

  return game.subgames && game.subgames.length > 0 ? (
    // If there are subgames, show a popover
    <Popover
      open={isPopoverOpen}
      handler={handlePopoverToggle}
      placement="bottom"
      offset={10}
    >
      <PopoverHandler>
        <div className="cursor-pointer">
          <GameCardContent />
        </div>
      </PopoverHandler>
      <PopoverContent>
        <div className="p-3">
          <h3 className="font-semibold mb-2 text-lg">Modes de jeu</h3>
          <div className="flex gap-3 flex-wrap">
            {game.subgames.map((subgame, index) => (
              <div className="flex-1" key={index}>
                <GameCard game={subgame} patientId={patientId} closeParent={() => setPopoverOpen(false)} />
              </div>
            ))}
          </div>
        </div>
      </PopoverContent>
    </Popover>
  ) : (
    <button onClick={handleGameClick}>
      <GameCardContent />
    </button>
  );
};
