import { TextStyle } from "@pixi/text";
import { SpaceGameState } from "./state/SpaceGameState";
import { CountDownComponent } from "../../../common/Component/CountDownComponent";
import { EndGameStatus } from "./state/EndGameStatus";
import { LivesComponents } from "../component/LivesComponent";
import { SpaceGameConfiguration } from "../SpaceGame";
import { Container, DisplayObject } from "@pixi/display";
import { TextComponent } from "../component/TextComponent";
import { saveGame } from "../../../common/server/SpaceGameParameter";
import { Entity } from "../../../common/entities/Entity";

export class SpaceGameEntity extends Entity {
    private static LIVES_TEXT = 'Vies : '
    private static WIN_TEXT = 'Gagné !!\nAppuyer sur ENTRÉE pour relancer'
    private static GAME_OVER = 'Perdu\nAppuyer sur ENTRÉE pour relancer'

    private _gameInstanceId: string;
    private _gameState: SpaceGameState;
    private _endStatus: EndGameStatus;
    private _gameStatusText: TextComponent;
    private _playerLives: LivesComponents;
    private _playerLivesText: TextComponent;
    private _timeRemainingText: TextComponent;
    private _countDown: CountDownComponent;
    private _scoreText: TextComponent;
    private _letter: number;
    private _words: number;

    constructor(
        private readonly screen: { width: number, height: number },
        config: SpaceGameConfiguration
    ) {
        super()

        const textStyle = new TextStyle({
            fill: 0xffffff,
            fontSize: screen.width * 0.025,
            fontFamily: 'Titan One'
        })

        this._gameInstanceId = config.gameInstanceId;
        this._gameState = SpaceGameState.NOT_STARTED;
        this._endStatus = EndGameStatus.NOT_FINISHED
        this._letter = 0;
        this._words = 0;
        this._gameStatusText = new TextComponent(
            '',
            {
                ...textStyle,
                fontSize: screen.width * 0.05,
                align: 'center'
            }
        );
        this._playerLivesText = new TextComponent(
            SpaceGameEntity.LIVES_TEXT,
            { ...textStyle, align: 'left' }
        );
        this._playerLives = new LivesComponents(
            config.playerLives,
            screen,
            config.infiniteLives
        );
        this._timeRemainingText = new TextComponent('Temps : ', textStyle)
        this._countDown = new CountDownComponent({ minutes: 1 }, textStyle)
        this._scoreText = new TextComponent(this.getScoreFormat(), { ...textStyle, align: 'right' });
        this.positionText();
    }

    private handleEndGameStatus() {
        if (this._playerLives.lives === 0 && !this._playerLives.isInfinite) {
            this._gameState = SpaceGameState.GAME_END
            this._endStatus = EndGameStatus.LOSE
            this._gameStatusText.text = SpaceGameEntity.GAME_OVER
            this._gameStatusText.position.x -= this._gameStatusText.width / 2
            this._gameStatusText.position.y -= this._gameStatusText.height / 2
            saveGame(this._gameInstanceId).then(() => {
                window.parent.postMessage("Game over")
            });
        }
        if (!this._countDown.isRunning) {
            this._gameState = SpaceGameState.GAME_END
            this._endStatus = EndGameStatus.WIN
            this._gameStatusText.text = SpaceGameEntity.WIN_TEXT
            this._gameStatusText.position.x -= this._gameStatusText.width / 2
            this._gameStatusText.position.y -= this._gameStatusText.height / 2
            saveGame(this._gameInstanceId).then(() => {
                window.parent.postMessage("Win")
            });
        }
    }

    private positionText() {
        this._gameStatusText.position.set(this.screen.width / 2, this.screen.height / 2);
        this._playerLivesText.position.x = this.screen.width * 0.01;
        this._playerLivesText.position.y = this.screen.height - this._playerLivesText.height * 2;
        this._playerLives.container.position.x = this.screen.width * 0.01 + this._playerLivesText.width;
        this._playerLives.container.position.y = this.screen.height - this._playerLivesText.height * 2;
        this._timeRemainingText.position.x = this.screen.width * 0.01;
        this._timeRemainingText.position.y = this.screen.height - this._playerLivesText.height;
        this._countDown.container.position.x = this._timeRemainingText.position.x
            + this._timeRemainingText.width;
        this._countDown.container.position.y = this.screen.height - this._playerLivesText.height;
        this._scoreText.position.x = this.screen.width - (this._scoreText.width + this.screen.width * 0.01);
        this._scoreText.position.y = this.screen.height - this._playerLivesText.height * 2;
    }

    private getScoreFormat(): string {
        const wordsNb = this._words.toString().padStart(3, '0')
        const lettersNb = this._letter.toString().padStart(3, '0')

        return `Mots: ${wordsNb}\nLettres: ${lettersNb}`;
    }

    private updateText() {
        this._gameStatusText.update(this.positionText.bind(this));
        this._playerLivesText.update(this.positionText.bind(this));
        this._timeRemainingText.update(this.positionText.bind(this));
        this._scoreText.update(this.positionText.bind(this));
    }

    public display(container: Container<DisplayObject>): void {
        this._container.addChild(this._playerLivesText);
        this._container.addChild(this._playerLives.container);
        this._container.addChild(this._gameStatusText);
        this._container.addChild(this._timeRemainingText);
        this._container.addChild(this._countDown.container)
        this._container.addChild(this._scoreText)
        
        container.addChild(this._container)
    }

    public update(_delta: number): void {
        switch (this._gameState) {
            case SpaceGameState.NOT_STARTED:
                this._gameState = SpaceGameState.STARTED;
                this._countDown.start();
                this.updateText();
                break;

            case SpaceGameState.STARTED:
                this.updateText();
                this._countDown.update();
                this.handleEndGameStatus();
                break;

            default:
                break;
        }
    }

    public hitPlayer() {
        this._playerLives.loseLife();
    }

    public countLetter() {
        this._letter++;
        this._scoreText.text = this.getScoreFormat();
        this._scoreText.position.x = this.screen.width - (this._scoreText.width + this.screen.width * 0.01);
    }

    public countWord() {
        this._words++;
        this._scoreText.text = this.getScoreFormat();
        this._scoreText.position.x = this.screen.width - (this._scoreText.width + this.screen.width * 0.01);
    }

    get gameState(): SpaceGameState {
        return this._gameState;
    }
}